const dev = {
  API_URL: "https://localhost:3000/api",
  REPORT_URL: "https://localhost:3000/reports",
  AUTH_URL: "https://localhost:3000",
  TOKEN_KEY: "ekauth:token",
  REFRESH_KEY: "ekauth:refreshToken",
  EXP_KEY: "ekauth:expiryDate",
  USER_INFO: "ekauth:user"
}
 
const test = {
  API_URL: "http://srv.gruporecover.com:8002/api",
  REPORT_URL: "https://srv.gruporecover.com:8002/reports",
  AUTH_URL: "http://srv.gruporecover.com:8002",
  TOKEN_KEY: "auth:token",
  REFRESH_KEY: "auth:refreshToken",
  EXP_KEY: "auth:expiryDate",
  USER_INFO: "auth:user",
}

const prod = {
  API_URL: "https://lexy.mra-legal.com:8002/api",
  REPORT_URL: "https://lexy.mra-legal.com:8002/reports",
  AUTH_URL: "https://lexy.mra-legal.com:8002",
  TOKEN_KEY: "auth:token",
  REFRESH_KEY: "auth:refreshToken",
  EXP_KEY: "auth:expiryDate",
  USER_INFO: "auth:user"
}

export const selectVariables = () => {
  switch (process.env.NODE_ENV) {
      case 'production':
          return prod
      case 'test':
      case 'stage':
          return test
      default:
          return dev
  }
}

export const TYPES = {
  APP_INIT: "APP_INIT",
  APP_SET_USER: "APP_SET_USER",
  APP_SET_HEADER: "APP_SET_HEADER",
  AUTH_REQUEST: "AUTH_REQUEST",
  AUTH_SUCCESS: "AUTH_SUCCESS",
  AUTH_FAILURE: "AUHT_FAILURE",
  AUTH_CLEAN: "AUTH_CLEAN",
  AUTH_SET_TOKEN: "AUTH_SET_TOKEN",  
  FETCH_DATA: "FETCH_DATA",
  SAVE_DATA: "SAVE_DATA",
  PUT_DATA: "PUT_DATA",
  UPDATE_DATA: "UPDATE_DATA",
  DELETE_DATA: "DELETE_DATA"
}

export const spanish = {
  dayNames: ["Domingo","Lunes","Martes","Miercoles","Jueves","Viernes","Sabado"],
  monthNames: ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio", "Agosto","Septiembre","Octubre","Noviembre","Diciembre"]
}

export const spaStrings = {
  'every': 'cada',
  'until': 'hasta',
  'day': 'dia',
  'days': 'dias',
  'week': 'semana',
  'weeks': 'semanas',
  'month': 'mes',
  'months': 'meses',
  'year': "año",
  'years': "años",
  'on': 'en',
  'for': 'por',
  'time': "vez",
  'times': "veces"
}

export const caseColor = s => {
  switch(s){
    case "nuevo":
      return "#3d692b"
    case "sin_movimiento":
      return "#525252"
    case "cerrado":
      return "#000"
    case "anulado":
      return "red"
    case "facturado":
      return "#0b4066"    
    case "para_facturar":
      return "#108ee9"
    case "pagado":
      return "#8fd672"
    case "bloqueado":
      return "#f50"
    case "solicitado":
      return "#353857"
    case "solicitado_parcial":
      return "#1d3e40"  
    default:
      return "#000"
  }
}

export const caseState =  [
  { text: 'Nuevo', value: 0 },
  { text: 'Sin Movimiento', value: 1 },
  { text: 'Cerrado', value: 2 },
  { text: 'Anulado', value: 3 },
  { text: 'Facturado', value: 4 },
  { text: 'Para Facturar', value: 5 },
  { text: 'Pagado', value: 6 },
  { text: 'Bloqueado', value: 7 },
  { text: "Solicitado", value: 8 },
  { text: "Solicitado Parcial", value: 9 }
]

export const stampColor = s => {
  switch(s){
    case "entregado":
      return "#3d692b"
    case "devuelto":
      return "#9f9f9f"
    case "solicitado":
      return "#0b4066"      
    case "atrasado":
      return "#f50"
    default:
      return ""
  }
}

export const stampState =  [
  { text: 'Solicitado', value: 1 },
  { text: 'Entregado', value: 2 },
  { text: 'Devuelto', value: 3 },
  { text: 'Atrasado', value: 4 }
]


export const feeColor = s => {
  switch(s){
    case "pagado":
      return "#3d692b"
    case "cobrando":
      return "#8fd672"
    case "anulado":
      return "#9f9f9f"
    case "pendiente_pago":
      return "#0b4066"
    default:
      return ""
  }
}

export const feeState =  [
  { text: 'Pendiente Pago', value: 1 },
  { text: 'Anulado', value: 3 },
  { text: 'Cobrando', value: 4 },
  { text: 'Pagado', value: 5 }
]

export const expenseColor = s => {
  switch(s){
    case "nuevo":
      return "#108ee9"
    case "pagado":
      return "#3d692b"
    case "cobrado":
      return "#8fd672"
    case "anulado":
      return "#000"
    case "pendiente_pago":
      return "#0b4066"
    case "solicitado":
      return "#353857"
    default:
      return ""
  }
}

export const expenseState = [
  { text: 'Nuevo', value: 0},
  { text: 'Pendiente de Pago', value: 1 },
  { text: 'Solicitado', value: 2 },
  { text: 'Anulado', value: 3 },
  { text: 'Cobrado', value: 4 },
  { text: 'Pagado', value: 5}
]

export const expenseRequestColor = s => {
  switch(s){
    case "liquidado":
      return "#3d692b"
    case "liquidado_parcial":
      return "#8fd672"
    case "anulado":
      return "#000"      
    case "pendiente":
      return "#f50"
    case "solicitado":
      return "#0b4066"
    case "aprobado":
      return "#108ee9"
    default:
      return ""
  }
}

export const expenseOfficeState = [
  { text: 'Nuevo', value: 0},
  { text: 'Pendiente de Pago', value: 1 },
  { text: 'Anulado', value: 3 },
  { text: 'Cobrado', value: 4 },
  { text: 'Pagado', value: 5}
]

export const requestColor = s => {
  switch(s){
    case "nuevo":
      return "#108ee9"
    case "aprobado":
      return "#0b4066"
    case "procesando":
      return "#8fd672"
    case "cerrado":
      return "#3d692b"  
    case "cancelado":
      return "#000"    
    default:
      return ""
  }
}

export const invoiceColor = s => {
  switch(s){
    case "nuevo":
      return "#108ee9"
    case "certificado":
      return "#0b4066"
    case "pagado":
      return "#3d692b"      
    case "anulado":
      return "#000"
      case "anulado_ncre":
      return "#000"
    default:
      return ""
  }
}

export const invoiceState =  [
  { text: 'Nuevo', value: 0 },
  { text: 'Certificado', value: 1 },
  { text: 'Pagado', value: 2 },
  { text: 'Anulado', value: 5 },
  { text: 'Anulado NCRE', value: 6 }
]

export const paymentState = [
  { text: 'Nuevo', value: 0 },
  { text: 'Liquidado', value: 1 },
  { text: 'Anulado', value: 2 }
]

export const paymentColor = s => {
  switch(s){
    case "nuevo":
      return "#108ee9"
    case "liquidado":
      return "#3d692b"
      case "anulado":
        return "#000"      
    default:
      return ""
  }
}

export const requestState = [
  { text: 'Nuevo', value: 0 },
  { text: 'Aprobado', value: 1 },
  { text: 'Procesando', value: 2 },
  { text: 'Cerrado', value: 3 },
  { text: 'Cancelado', value: 5 }
]

export const requestType = [
  { text: 'Factura', value: 1 },
  { text: 'Factura Exportación', value: 2 },
  { text: 'Recibo', value: 0 },
]

export const typeColor = s => {
  switch(s){
    case 0:
      return "#c7afaf"
    case 1:
      return "#acbe87"
    case 2:
        return "#a7c9f8"      
    default:
      return ""
  }
}

export const expenseRequestState = [
  { text: 'Solicitado', value: 1 },
  { text: 'Otro', value: 2 },
  { text: 'Aprobado', value: 3 },
  { text: 'Liquidado Parcial', value: 4 },
  { text: 'Liquidado', value: 5 },
  { text: 'Anulado', value: 6 },
  { text: 'Pendiente', value: 7 }
]

export const styleContent = {
  content: {
    marginTop: 5,
    padding: 24, 
    background: '#fff', 
    minHeight: "85vh" 
  },
  header: {
    margin: "0px 0px 5px 0px",
    padding: "10px 20px 10px",
    background: "#fff"
  },
  headerLink: {
    color: '#1f1f1f !important',
    fontWeight: 'bold'
  },
  tagState: {
    width: "100%", 
    textAlign: "center"
  },
  highlight: {
    backgroundColor: "rgba(65, 114, 135, 0.35)",
  }
}

export const styleDescription = {
  contentStyle: {        
      border: "1px solid rgb(47 76 89 / 20%)"
  },
  labelCol: {
      backgroundColor: "#bae2e2", 
      border: "1px solid rgb(47 76 89 / 20%)"
  }
}

export const toolbar_editor = [
  ['bold', 'italic', 'underline','strike', 'blockquote'],
  [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
]

export const stateSnake = s => s.split("_").map(w => w.toUpperCase()).join(" ")

export const write_access = ['master','admin']

export const VARS = selectVariables()
