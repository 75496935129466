import axios from 'axios'

import { TYPES, VARS } from '../constants'
import { authGetToken } from './'
import { handleErrors } from './notification'

const { FETCH_DATA, SAVE_DATA, PUT_DATA, UPDATE_DATA, DELETE_DATA } = TYPES

export const getData = (url,objectName,options={}) => 
    dispatch => {
        dispatch(apiAction(FETCH_DATA,objectName))
        return new Promise(async (resolve, reject) => {
            try{
                const token = await dispatch(authGetToken())
                options.headers = {'Authorization': `Bearer ${token}`}
                const response = await axios.get(`${VARS.API_URL}/${url}`, options)
                resolve(response.data)
            }catch(e){
                handleErrors(e)
                reject("Server data error.")
            }
        })
    }

export const openPDF = (url,objectName,options={}) => 
    dispatch => {
        dispatch(apiAction(FETCH_DATA, objectName))
        return new Promise(async (resolve, reject) => {
            try{
                const token = await dispatch(authGetToken())
                options.headers = {'Authorization': `Bearer ${token}`}
                options.responseType = "blob"
                const response = await axios.get(`${VARS.API_URL}/${url}`, options)
                const file = new Blob([response.data], { type: "application/pdf" })
                const fileURL = URL.createObjectURL(file)
                window.open(fileURL)
                resolve(true)
            }catch(e){
                handleErrors(e)
                reject(e)
            }
        })
    }

    export const exportData = (url,objectName) => 
        dispatch => {
            dispatch(apiAction(FETCH_DATA, objectName))
            return new Promise(async (resolve, reject) => {
                try{
                    const token = await dispatch(authGetToken())
                    const headers = {'Authorization': `Bearer ${token}`}                
                    const response = await fetch(`${VARS.REPORT_URL}/${url}`, {method: 'GET', responseType: 'blob', headers})
                    if(response.status === 200){
                        const blob = await response.blob()  
                        const uri = window.URL.createObjectURL(new Blob([blob]))
                        const link = document.createElement('a')
                        const filename = `${url.split('.')[0]}.xlsx`
                        link.href = uri;
                        link.setAttribute('download',filename)         
                        document.body.appendChild(link)
                        link.click()
                        link.parentNode.removeChild(link)    
                        resolve(uri)    
                    }else{
                        reject("Respond fail")
                    }        
                }catch(e){
                    handleErrors(e)
                    reject(e)
                }
            })
        }
    
export const saveData = (url,data,objectName,options={}) => 
    dispatch => {
        dispatch(apiAction(SAVE_DATA,objectName))
        return new Promise(async (resolve, reject) => {
            try{
                const token = await dispatch(authGetToken())
                options.headers = {
                    'Authorization': `Bearer ${token}`
                }
                const response = await axios.post(`${VARS.API_URL}/${url}`, data, options)
                resolve(response.data)
            }catch(e){
                handleErrors(e)
                reject(e)
            }
        })
    }

export const putData = (url,data,objectName,options={}) => 
    dispatch => {
        dispatch(apiAction(PUT_DATA,objectName))
        return new Promise(async (resolve, reject) => {
            try{
                const token = await dispatch(authGetToken())
                options.headers = {'Authorization': `Bearer ${token}`}
                const response = await axios.put(`${VARS.API_URL}/${url}`, data, options)
                resolve(response.data)
            }catch(e){
                handleErrors(e)
                reject(e)
            }
        })
    }

export const updateData = (url,data,objectName,options={}) => 
    dispatch => {
        dispatch(apiAction(UPDATE_DATA,objectName))
        return new Promise(async (resolve, reject) => {
            try{
                const token = await dispatch(authGetToken())
                options.headers = {'Authorization': `Bearer ${token}`}
                const response = await axios.patch(`${VARS.API_URL}/${url}`, data, options)
                resolve(response.data)
            }catch(e){
                handleErrors(e)
                reject(e)
            }
        })
    }
    
export const deleteData = (url,objectName,options={}) => 
    dispatch => {
        dispatch(apiAction(DELETE_DATA,objectName))
        return new Promise(async (resolve, reject) => {
            try{
                const token = await dispatch(authGetToken())
                options.headers = {'Authorization': `Bearer ${token}`}
                const response = await axios.delete(`${VARS.API_URL}/${url}`, options)
                resolve(response.data)
            }catch(e){
                handleErrors(e)
                reject(e)
            }
        })
    }

export const apiAction = (type, objectName) => ({type, objectName})
